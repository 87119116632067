var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tieLockDetails" }, [
    _c(
      "div",
      { staticClass: "tieLockDetails-lt" },
      _vm._l(_vm.dataListLeft, function(item) {
        return _c("div", { key: item.key, staticClass: "content-box" }, [
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.label))]),
          _c("span", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.form[item.key]) + _vm._s(item.value))
          ])
        ])
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "tieLockDetails-rt" },
      _vm._l(_vm.dataListRight, function(item) {
        return _c("div", { key: item.key, staticClass: "content-box" }, [
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.label))]),
          _c("span", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.form[item.key]) + _vm._s(item.value))
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }